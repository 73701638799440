import locomotiveScroll from "locomotive-scroll";

const scroll = new locomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    multiplier: .5 
});

console.log('test')

import './styles.css'
import './base.css'